import { useLoyaltyCurrency } from '@/hooks/bootstrap/bootstrap-hooks';
import { useCallback } from 'react';

import { useNumberFormatter } from 'react-aria';

export function usePointsFormatter() {
  const loyaltyCurrency = useLoyaltyCurrency();
  const numberFormatter = useNumberFormatter();

  return useCallback(
    (points: number | undefined, currency?: string) => {
      return points !== undefined
        ? `${numberFormatter.format(points)} ${
            currency || loyaltyCurrency?.name
          }`
        : '';
    },
    [numberFormatter, loyaltyCurrency]
  );
}
