import { LoyaltyProgramType } from '@/schema/loyalty-program.schema';
import { OrderItemType } from '@/schema/order/common';
import { z } from 'zod';

export enum RecommendedProductsDataItemTypes {
  PointsTransfer = 'PointsTransfer',
  Crypto = 'Crypto',
  GiftCard = 'GiftCard',
  CashRedemption = 'CashRedemption',
  Sustainability = 'Sustainability',
  Voucher = 'Voucher',
}

export const RecommendedProductsDataItemSchema = z.object({
  id: z.string(),
  type: z.enum([
    RecommendedProductsDataItemTypes.CashRedemption,
    RecommendedProductsDataItemTypes.Crypto,
    RecommendedProductsDataItemTypes.GiftCard,
    RecommendedProductsDataItemTypes.PointsTransfer,
    RecommendedProductsDataItemTypes.Sustainability,
    RecommendedProductsDataItemTypes.Voucher,
  ]),
  score: z.number(),
});

export const RecommendedProductsDataSchema = z.array(
  RecommendedProductsDataItemSchema,
);

export type RecommendedProductsData = z.infer<
  typeof RecommendedProductsDataSchema
>;

export type RecommendedProductsDataItem = z.infer<
  typeof RecommendedProductsDataItemSchema
>;

export const LoyaltyProgramTypeMapper: {
  [key in RecommendedProductsDataItemTypes]?: LoyaltyProgramType;
} = {
  [RecommendedProductsDataItemTypes.Crypto]: 'crypto',
  [RecommendedProductsDataItemTypes.CashRedemption]: 'cashback',
  [RecommendedProductsDataItemTypes.PointsTransfer]: 'points_transfer',
  [RecommendedProductsDataItemTypes.Sustainability]: 'sustainability',
  [RecommendedProductsDataItemTypes.Voucher]: 'voucher',
};

export const RecommendedProductTypeMapper: {
  [key in LoyaltyProgramType]?: RecommendedProductsDataItemTypes;
} = {
  crypto: RecommendedProductsDataItemTypes.Crypto,
  cashback: RecommendedProductsDataItemTypes.CashRedemption,
  points_transfer: RecommendedProductsDataItemTypes.PointsTransfer,
  sustainability: RecommendedProductsDataItemTypes.Sustainability,
  voucher: RecommendedProductsDataItemTypes.Voucher,
};

export const RecommendedOrderTypeMapper: {
  [key in Partial<OrderItemType>]?: RecommendedProductsDataItemTypes;
} = {
  gift_card_order_item: RecommendedProductsDataItemTypes.GiftCard,
  points_transfer_order_item: RecommendedProductsDataItemTypes.PointsTransfer,
  crypto_order_item: RecommendedProductsDataItemTypes.Crypto,
  sustainability_order_item: RecommendedProductsDataItemTypes.Sustainability,
  cash_redemption_order_item: RecommendedProductsDataItemTypes.CashRedemption,
  voucher_order_item: RecommendedProductsDataItemTypes.Voucher,
};

export type RecommendedProductsBannerVariants =
  | 'confirmation'
  | 'product_details';

export interface RecommendedBannerItemGtmProps {
  id_0: string;
  name_0: string;
  ranking: number;
  score_0?: number /** todo(rongsen): this is only needed for RFY banner and await BE support in listing pages */;
  type_0: RecommendedProductsDataItem['type'];
}

export interface RecommendedProduct
  extends Pick<RecommendedBannerItemGtmProps, 'ranking'>,
    RecommendedProductsDataItem {}
