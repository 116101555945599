export function useFetch() {
  let headers = new Headers();
  headers.set('Content-Type', 'application/json');

  return (...args: Parameters<typeof fetch>) => {
    const [path, options] = args;

    if (options?.headers) {
      // @ts-expect-error: options.headers should be an object
      // so we can iterate over it
      for (const [key, value] of options.headers.entries()) {
        headers.set(key, value);
      }
    }

    return fetch(path, {
      ...options,
      headers,
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res;
      })
      .then((res: Response) => {
        //TODO: refactor to not use hacky 'startsWith'
        return res.status.toString().startsWith('2')
          ? res.text().then(function (text) {
              return text?.trim() ? JSON.parse(text) : {};
            })
          : { success: true };
      });
  };
}
