'use client';

import { cva } from 'class-variance-authority';
import {
  ComponentProps,
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  MouseEventHandler,
  ReactNode,
} from 'react';

import useComponentConfig from '@/hooks/config/use-component-config';
import { Link } from '@/i18n/navigation';
import { cn } from '@/utils/tailwind';

export enum RcLinkVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  BUTTON = 'button',
}

const linkVariants = cva('', {
  variants: {
    variant: {
      [RcLinkVariant.PRIMARY]: 'text-primary hover:text-secondary',
      [RcLinkVariant.SECONDARY]: 'text-white hover:text-primary',
      // basic variant to look like a default primary button used in modal at the moment
      // we might need to change this in the future if we want to support different button styles
      [RcLinkVariant.BUTTON]: cn(
        'w-full justify-center lg:w-auto',
        'px-12 py-4',
        'font-medium inline-flex border-primary text-sm font-bold outline-none transition-colors',
        // focus ring
        'data-[focus-visible]:ring-accent data-[focus-visible]:outline-none data-[focus-visible]:ring-[3px] data-[focus-visible]:ring-offset-0',
        'focus:outline-none focus:ring-[3px] focus:ring-secondary focus:ring-offset-0',
        'text-[16px] leading-[24px] lg:leading-[26px]',
        'items-center border-primary bg-primary text-white',
        // border configuration
        'primary-button-border-width',
        'rounded-button-primary',
        // hover
        'hover:border-primary-400 hover:bg-primary-400',
        'data-[hovered]:border-primary-400 data-[hovered]:bg-primary-400',
        // pressed
        'active:border-primary-600 active:bg-primary-600',
      ),
    },
  },
  defaultVariants: {
    variant: RcLinkVariant.PRIMARY,
  },
});

export interface RcLinkProps {
  variant?: RcLinkVariant;
  href: string;
  children: ReactNode;
  className?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  props?: ComponentProps<typeof Link>;
}

export const BaseRcLink = forwardRef<
  React.ElementRef<typeof Link>,
  React.ComponentPropsWithoutRef<typeof Link> & RcLinkProps
>(
  (
    {
      variant = RcLinkVariant.PRIMARY,
      href,
      children,
      className,
      onClick,
      ...props
    }: RcLinkProps,
    ref,
  ) => {
    const variantStyles =
      variant === RcLinkVariant.SECONDARY
        ? 'navLinkSecondary'
        : 'navLinkPrimary';

    return (
      <Link
        onClick={onClick}
        href={href}
        className={cn(
          linkVariants({ variant, className }),
          'focus-link',
          className,
        )}
        scroll={
          // TODO: doesn't work, fix later
          typeof href === 'string' && !href.startsWith('/#')
        }
        {...props}
        ref={ref}
      >
        {children}
      </Link>
    );
  },
);

BaseRcLink.displayName = 'BaseRcLink';

type TypedBaseRcLink = typeof BaseRcLink;

export const RcLink = forwardRef<
  ElementRef<TypedBaseRcLink>,
  ComponentPropsWithoutRef<TypedBaseRcLink>
>((props, ref) => {
  const openInSameTab = useComponentConfig('links')?.openExternalLinksInSameTab
    ? {
        target: '',
      }
    : {};

  return <BaseRcLink {...props} {...openInSameTab} ref={ref} />;
});

RcLink.displayName = 'RcLink';
