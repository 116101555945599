import { paginationMetaSchema } from '@/schema/shared-schema';
import { z } from 'zod';

export const giftCardSchema = z.object({
  id: z.string(),
  type: z.literal('gift_card'),
  name: z.string(),
  categories: z.array(z.string()),
  logoUrl: z.string(),
  imageUrls: z.array(z.string()),
  description: z.string(),
  shortDescription: z.string().optional(),
  maxDiscountPercentage: z.number(),
  displayTypes: z.array(z.string()),
  available: z.boolean(),
  redemptionChannel: z.string().optional(),
  redemptionInstructions: z.array(z.string()).optional(),
  termsAndConditions: z.string().optional(),
  denominations: z.array(
    z.object({
      id: z.string(),
      displayType: z.string(),
      displayValue: z.number(),
      originalCardId: z.string().optional(),
      price: z.number(),
      pricingParameters: z.object({
        points: z.object({
          value: z.number(),
          min: z.number(),
          max: z.number(),
        }),
        cash: z.object({
          value: z.number(),
          min: z.number(),
          max: z.number(),
        }),
      }),
      maxQuantity: z.number(),
      deliveryMethod: z.string(),
      validityInDays: z.number(),
      minValidityInDays: z.null(),
    }),
  ),
});

export const giftCardArraySchema = z.array(giftCardSchema);
export type GiftCard = z.infer<typeof giftCardSchema>;

export const giftCardResponseSchema = z.object({
  data: giftCardArraySchema,
  meta: paginationMetaSchema,
});
export type GiftCardResponse = z.infer<typeof giftCardResponseSchema>;
