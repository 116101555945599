import type { ComponentProps } from 'react';

import { RcLink } from '@/components/rc-link';
import { useCurrency } from '@/hooks/bootstrap/bootstrap-hooks';
import {
  PRODUCT_TILE_1_IMAGE_HEIGHT,
  PRODUCT_TILE_1_IMAGE_HEIGHT_MOBILE,
  PRODUCT_TILE_1_IMAGE_WIDTH,
  PRODUCT_TILE_1_IMAGE_WIDTH_MOBILE,
} from '@/utils/constants';
import { cn } from '@/utils/tailwind';
import { useIsMobile } from '@/utils/use-is-mobile';
import { useNumberFormatter } from 'react-aria';

interface ProductTile1Props
  extends Pick<ComponentProps<typeof RcLink>, 'className' | 'onClick'> {
  dataGtm?: string;
  link: string;
  imageUrl: string;
  productName: string;
  denominationRange: [number, number];
  minimumRedemption: number;
}

/**
 * Gift Card and Merch Products
 */
export default function ProductTile1(props: ProductTile1Props) {
  const currency = useCurrency();
  const numberFormatter = useNumberFormatter();
  const isMobile = useIsMobile();
  const imageHeight = isMobile
    ? PRODUCT_TILE_1_IMAGE_HEIGHT_MOBILE
    : PRODUCT_TILE_1_IMAGE_HEIGHT;
  const imageWidth = isMobile
    ? PRODUCT_TILE_1_IMAGE_WIDTH_MOBILE
    : PRODUCT_TILE_1_IMAGE_WIDTH;

  function renderDenominationRange(low: number, high: number) {
    if (low === high) {
      return (
        <>{`${currency ? currency.symbol : ''}${numberFormatter.format(
          props.denominationRange[0],
        )}`}</>
      );
    }
    return (
      <>
        {`${currency ? currency.symbol : ''}${numberFormatter.format(
          props.denominationRange[0],
        )} - ${currency ? currency.symbol : ''}${numberFormatter.format(
          props.denominationRange[1],
        )}`}
      </>
    );
  }

  function getDenominationRangeAriaLabel(low: number, high: number) {
    if (low === high) {
      return `${currency ? currency.symbol : ''}${numberFormatter.format(
        props.denominationRange[0],
      )}`;
    }
    return `Denomination ranging from ${
      currency ? currency.symbol : ''
    }${numberFormatter.format(props.denominationRange[0])} to ${
      currency ? currency.symbol : ''
    }${numberFormatter.format(props.denominationRange[1])}`;
  }

  return (
    <RcLink
      data-gtm={props.dataGtm}
      href={props.link}
      className={cn('rounded-lg', props.className)}
      onClick={props.onClick}
    >
      <div className="flex h-full flex-col items-center rounded-lg border border-neutral-400 bg-white hover:border-black">
        <div className="flex max-h-[179px] w-full items-center justify-center border-b border-neutral-400 p-6">
          <img
            className={cn(
              'mx-auto w-[247px] rounded-lg object-contain drop-shadow-md lg:h-[115px] lg:w-[184px]',
            )}
            height={imageHeight}
            width={imageWidth}
            src={props.imageUrl}
            alt=""
          />
        </div>
        <div className="flex h-full w-full flex-col justify-between px-4 py-[20px] lg:p-6">
          <div>
            <h2 className="mb-2 font-normal tracking-tight text-neutral-600">
              {props.productName}
            </h2>
            <p className="mb-3 font-bold text-black ">
              <span
                aria-label={getDenominationRangeAriaLabel(
                  props.denominationRange[0],
                  props.denominationRange[1],
                )}
              >
                {renderDenominationRange(
                  props.denominationRange[0],
                  props.denominationRange[1],
                )}
              </span>
            </p>
          </div>
        </div>
      </div>
    </RcLink>
  );
}
