import { HomepageProductsCarousel } from '@/components/homepage-products-carousel/homepage-products-carousel';
import { getPathFromLoyaltyProgram } from '@/features/points-transfer/utils';
import { useTranslation } from '@/translation/translation';
import { cn } from '@/utils/tailwind';
import { LoyaltyProgramTypeMapper, RecommendedProduct } from '../types';
import {
  RecommendedBannerProps,
  isGiftCard,
  isLoyaltyProgram,
} from './recommended-banner';
import { RecommendedProductV2 } from './recommended-product-v2';

/** FIXME(rongsen): i18n integration */
export const ProductTypeToRecommendationItemTitle = {
  PointsTransfer: 'Transfer',
  Crypto: 'Crypto',
  GiftCard: 'Gift Cards',
  CashRedemption: 'Cashback',
  Sustainability: 'Sustainability',
  Voucher: 'Uber Credit',
} as const;

export function RecommendedBannerV2({
  title,
  recommendedProducts,
  results,
  onClick,
  className,
}: RecommendedBannerProps) {
  const { t } = useTranslation();

  return (
    <HomepageProductsCarousel
      className={cn('gap-4 container-responsive lg:gap-6', className)}
      viewableClassName="mx-[-112px] px-[112px]"
      carouselWrapperClassName="gap-4 lg:gap-6"
      titleComponent={<p className="text-heading-2">{t(title)}</p>}
    >
      {results.map(({ data }, idx) => {
        if (!data || typeof data === 'function') return null;

        const { id, type, score } = recommendedProducts[idx];
        const recommendedProduct: RecommendedProduct = {
          id,
          type,
          score,
          ranking: idx,
        };

        const handleClick = <T extends { name: string }>(product: T) => {
          onClick?.({
            id_0: id,
            name_0: product.name,
            ranking: idx,
            score_0: score,
            type_0: type,
          });
        };

        let productImageUrl = '';
        let productLink = '';

        if (isGiftCard(data)) {
          productImageUrl = data.imageUrls[0];
          productLink = `/products/gift-cards/${data.id}`;
        }

        if (isLoyaltyProgram(data)) {
          productImageUrl = data.capabilities[0].logoUrl;
          productLink =
            getPathFromLoyaltyProgram(LoyaltyProgramTypeMapper[type]!) +
            data.id;
        }

        return (
          <RecommendedProductV2
            key={idx}
            onClick={() => handleClick(data)}
            className="gtm:rfy shrink-0 gap-2"
            recommendedProduct={recommendedProduct}
            productName={data.name}
            productType={
              ProductTypeToRecommendationItemTitle[recommendedProduct.type]
            }
            productImageUrl={productImageUrl}
            productLink={productLink}
          />
        );
      })}
    </HomepageProductsCarousel>
  );
}
