import { Currency } from '@/schema/bootstrap.schema';
import { useBootstrapData } from '../../store/store';

export function useBootstrapLocale() {
  const bootstrap = useBootstrapData();
  return bootstrap?.locales[0];
}

export function useCurrency(): Currency | null {
  const bootstrap = useBootstrapData();

  return bootstrap?.cashCurrency || null;
}

export function useLoyaltyCurrency() {
  const bootstrap = useBootstrapData();
  return bootstrap?.loyaltyCurrencies[0];
}
