'use client';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Todo } from 'global';

import { useFetch } from '@/hooks/fetch/use-fetch';
import { queryKeys } from '@/queries/query-factory';
import {
  CashbackLoyaltyProgram,
  cashbackLoyaltyProgramSchema,
  CryptoLoyaltyProgram,
  cryptoLoyaltyProgramSchema,
  FrequentTravelerLoyaltyProgram,
  frequentTravelerLoyaltyProgramSchema,
  LoyaltyProgramType,
  loyaltyProgramTypeSchema,
  SustainabilityLoyaltyProgram,
  sustainabilityLoyaltyProgramSchema,
  VoucherLoyaltyProgram,
  VoucherLoyaltyProgramSchema,
} from '@/schema/loyalty-program.schema';
import { validateSchema } from '@/schema/validate-schema';
import { getClientUrl } from '@/utils/get-client-url';
import { useSentryMonitoring } from '../monitoring/use-sentry-monitoring';

export function isCashbackLoyaltyProgram(
  lp:
    | CashbackLoyaltyProgram
    | FrequentTravelerLoyaltyProgram
    | CryptoLoyaltyProgram
    | SustainabilityLoyaltyProgram
    | VoucherLoyaltyProgram
    | undefined
    | null,
): lp is CashbackLoyaltyProgram {
  return lp
    ? lp.category === 'cashback_to_platform' ||
        lp.category === 'cashback_to_tenant'
    : false;
}

export function isFrequentTravelerLoyaltyProgram(
  lp:
    | CashbackLoyaltyProgram
    | FrequentTravelerLoyaltyProgram
    | CryptoLoyaltyProgram
    | SustainabilityLoyaltyProgram
    | VoucherLoyaltyProgram
    | undefined
    | null,
): lp is FrequentTravelerLoyaltyProgram {
  return lp ? lp.category === 'airline' || lp.category === 'hotel' : false;
}

export function isSustainabilityLoyaltyProgram(
  lp:
    | CashbackLoyaltyProgram
    | FrequentTravelerLoyaltyProgram
    | CryptoLoyaltyProgram
    | SustainabilityLoyaltyProgram
    | VoucherLoyaltyProgram
    | undefined
    | null,
): lp is SustainabilityLoyaltyProgram {
  return lp ? lp.category === 'sustainability' : false;
}

export function isCryptoLoyaltyProgram(
  lp:
    | CashbackLoyaltyProgram
    | FrequentTravelerLoyaltyProgram
    | CryptoLoyaltyProgram
    | SustainabilityLoyaltyProgram
    | VoucherLoyaltyProgram
    | null
    | undefined,
): lp is CryptoLoyaltyProgram {
  return lp ? lp.category === 'crypto' : false;
}

export function loyaltyProgramByTypeAndIdOptions({
  id,
  loyaltyProgramType,
  enabled = true,
  sentryLog,
  queryClient,
  fetch,
}: {
  id: string;
  loyaltyProgramType: LoyaltyProgramType;
  enabled?: boolean;
  sentryLog: ReturnType<typeof useSentryMonitoring>['sentryLog'];
  queryClient: ReturnType<typeof useQueryClient>;
  fetch: ReturnType<typeof useFetch>;
}) {
  const baseUrl = getClientUrl();
  const headers = new Headers();

  headers.set('X-Response-Format', 'custom');

  let url = '';
  url =
    loyaltyProgramType === loyaltyProgramTypeSchema.enum.cashback
      ? `${baseUrl}/api/products/cashback/${id}`
      : `${baseUrl}/api/products/points_transfer/${id}`;

  return {
    queryKey: queryKeys.loyaltyProgram.detail(id).queryKey,
    queryFn: async () => {
      const data = await fetch(url, {
        headers,
      });
      switch (loyaltyProgramType) {
        case loyaltyProgramTypeSchema.enum.points_transfer: {
          return validateSchema({
            data,
            schemaName: 'frequent-traveler-item-schema',
            zodSchema: frequentTravelerLoyaltyProgramSchema,
          });
        }
        case loyaltyProgramTypeSchema.enum.cashback: {
          return validateSchema({
            data,
            schemaName: 'cashback-item-schema',
            zodSchema: cashbackLoyaltyProgramSchema,
          });
        }
        case loyaltyProgramTypeSchema.enum.crypto: {
          return validateSchema({
            data,
            schemaName: 'crypto-item-schema',
            zodSchema: cryptoLoyaltyProgramSchema,
          });
        }
        case loyaltyProgramTypeSchema.enum.sustainability: {
          return validateSchema({
            data,
            schemaName: 'sustainability-item-schema',
            zodSchema: sustainabilityLoyaltyProgramSchema,
          });
        }
        case loyaltyProgramTypeSchema.enum.voucher: {
          return validateSchema({
            data,
            schemaName: 'uber-credit-item-schema',
            zodSchema: VoucherLoyaltyProgramSchema,
          });
        }
        default: {
          return null;
        }
      }
    },
    initialData: () => {
      // Using the pull approach
      // https://tkdodo.eu/blog/seeding-the-query-cache#pull-approach
      let data;
      // since the list page use pagination so we need to find giftCard in all listing queries
      // https://tanstack.com/query/v4/docs/react/reference/QueryClient#queryclientgetqueriesdata
      queryClient
        .getQueriesData({
          queryKey: queryKeys.products.list._def,
        })
        ?.find(
          ([
            _queryKey,
            productData,
          ]: // Money 20/20: don't have time to make it type safe
          [Todo, Todo]) => {
            data = productData?.data.find((product: Todo) => {
              return product.id === id;
            });
            return data;
          },
        );
      return data;
    },
    enabled,
  };
}

export default function useLoyaltyProgramByTypeAndId({
  id,
  loyaltyProgramType,
  enabled = true,
}: {
  id: string;
  loyaltyProgramType: LoyaltyProgramType;
  enabled?: boolean;
}) {
  const queryClient = useQueryClient();
  const fetch = useFetch();
  const { sentryLog } = useSentryMonitoring();

  const { isLoading, isError, data, error, isFetched } = useQuery(
    loyaltyProgramByTypeAndIdOptions({
      id,
      loyaltyProgramType,
      enabled,
      sentryLog,
      queryClient,
      fetch,
    }),
  );

  return {
    isFetched,
    isLoading,
    isError,
    error,
    loyaltyProgram: data,
  };
}
