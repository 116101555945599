'use client';

import { Icon } from '@/components/icon';
import { cn } from '@/utils/tailwind';
import { useContext } from 'react';
import { Button } from '../button/button';
import { CarouselContext } from './carousel';

interface CarouselArrowWithPaginationProps {
  currentIndex?: number;
  className?: string;
  showPagination?: boolean;
  hideOnNotSlidable?: boolean;
}

export function CarouselArrowWithPagination({
  currentIndex,
  className,
  showPagination = false,
  hideOnNotSlidable = false,
}: CarouselArrowWithPaginationProps) {
  const {
    prevBtnDisabled,
    onPrevButtonClick,
    nextBtnDisabled,
    onNextButtonClick,
    scrollSnaps,
    selectedIndex: indexFromCarousel,
  } = useContext(CarouselContext);

  // We prefer using currentIndex if it's provided as it's more accurate
  // If we have multiple pagination components in each carousel item
  // indexFromCarousel will shown the same index for all of them
  const carouselItemPageNumber = (currentIndex || indexFromCarousel) + 1;

  return (
    <div
      className={cn(
        'flex w-fit flex-row items-center justify-center gap-2 rounded-custom bg-neutral-100 p-1',
        { hidden: hideOnNotSlidable && scrollSnaps.length <= 1 },
        className,
      )}
    >
      <Button
        icon="iconOnly"
        isDisabled={prevBtnDisabled}
        onPress={onPrevButtonClick}
        size="icon"
        noBorder
        variant={null}
        className={cn('h-6 w-6 focus:ring-0', {
          'text-neutral-300': prevBtnDisabled,
        })}
      >
        <Icon name="chevron-left" className="h-4 w-4" />
      </Button>

      {showPagination ? (
        <p className={cn('font-normal text-neutral-600')}>
          {carouselItemPageNumber}/{scrollSnaps.length}
        </p>
      ) : null}

      <Button
        icon="iconOnly"
        isDisabled={nextBtnDisabled}
        onPress={onNextButtonClick}
        size="icon"
        noBorder
        variant={null}
        className={cn('h-6 w-6 focus:ring-0', {
          'text-neutral-300': nextBtnDisabled,
        })}
      >
        <Icon name="chevron-right" className="h-4 w-4" />
      </Button>
    </div>
  );
}
