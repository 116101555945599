import { RewardsCentralConfiguration } from '@/config-schema/rewards-central-config';
import useApplicationConfig from '@/hooks/config/use-application-config';

export function useFeatureConfig():
  | RewardsCentralConfiguration['application']['features']
  | undefined;

export function useFeatureConfig<
  Feature extends keyof RewardsCentralConfiguration['application']['features']
>(
  key: Feature
): RewardsCentralConfiguration['application']['features'][Feature];

export function useFeatureConfig<
  Feature extends keyof RewardsCentralConfiguration['application']['features']
>(
  key?: Feature
):
  | RewardsCentralConfiguration['application']['features']
  | RewardsCentralConfiguration['application']['features'][Feature] {
  const config = useApplicationConfig()?.features;
  if (!key) {
    return config;
  }

  return config && config[key];
}
