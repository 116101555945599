import { roundNumber } from '@/utils/misc';
import { cn } from '@/utils/tailwind';
import { useMemo } from 'react';
import { Icon } from './icon';

export function DiscountTag({
  discountPercentage,
  className,
}: {
  discountPercentage: number;
  className?: string;
}) {
  const discountPercentLabel: string = useMemo(() => {
    return discountPercentage
      ? `${roundNumber(discountPercentage * 100, 0)}% bonus`
      : '';
  }, [discountPercentage]);
  return (
    <span
      className={cn(
        'bg-primary-200 text-primary text-sm font-normal',
        'flex gap-2 rounded-full px-2',
        className
      )}
    >
      <Icon name="gift" className="fill-primary h-[14px] w-[14px]" />
      {discountPercentLabel}
    </span>
  );
}
