import { GiftCard } from '@/features/gift-card/gift-card.schema';
import { Currency } from '@/schema/bootstrap.schema';
import { numberFormatter } from '@/utils/formatter';

export function getMaxDenomination(giftCard: GiftCard) {
  return giftCard.denominations.reduce(
    (acc, denomination) =>
      denomination.displayValue > acc.displayValue ? denomination : acc,
    // Adding a placeholder price to enable typing
    // we can omit this initial value but if we do so when
    // the array is empty and an error will be thrown
    { displayValue: 0, price: 0 },
  );
}

export function getMinDenomination(giftCard: GiftCard) {
  return giftCard.denominations.reduce(
    (acc, denomination) =>
      denomination.displayValue < acc.displayValue ? denomination : acc,
    // Adding a placeholder price to enable typing
    // we can omit this initial value but if we do so when
    // the array is empty and an error will be thrown
    { displayValue: Number.MAX_VALUE, price: 0 },
  );
}

export function getDisplayCurrency(
  giftCard: GiftCard,
  currency: Currency['symbol'],
) {
  const maxDenomination = getMaxDenomination(giftCard);
  const minDenomination = getMinDenomination(giftCard);

  if (minDenomination === maxDenomination) {
    return `${currency}${numberFormatter.format(minDenomination.displayValue)}`;
  }

  return `${currency}${numberFormatter.format(
    minDenomination.displayValue,
  )} - ${currency}${numberFormatter.format(maxDenomination.displayValue)}`;
}
